import withAPIAction from 'helpers/withAPIAction'
import {
  DetailReq,
  GetCustomerReq,
  ListReq,
  UpdateCustomerReq,
  UpdateCustomerSocketReq,
  UpdateReq,
  UpdateStatusReq,
  CountReq,
  BranchReq,
  ReasonReq,
  AssignReq,
  PostcodeReq,
  DeliveryStaffReq,
} from './types'
import {
  getDetailAction,
  getListAction,
  updateAction,
  updateCustomerStatusAction,
  countAllTabAction,
  countMeTabAction,
  getBranchListAction,
  assignTaskAction,
  getReasonAction,
  getPostcodeListAction,
  getStaffListAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const getAllCustomerTaskListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getAllCustomerTaskList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getMeCustomerTaskListAPI = (data: ListReq, token: string, isAgentChatSale: boolean = false) => {
  const endPoint = isAgentChatSale ? APIPath.getChatSaleCustomerTaskList : APIPath.getMeCustomerTaskList
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${endPoint}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getAllCarloanDetailAPI = (data: DetailReq, token: string) => {
  const endPoint = APIPath.allCarloanByID.replace(':id', data.id.toString())
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const getMeCarloanDetailAPI = (data: DetailReq, token: string, isAgentChatSale: boolean = false) => {
  const path = isAgentChatSale ? APIPath.chatSaleCarloanByID : APIPath.meCarloanByID
  const endPoint = path.replace(':id', data.id.toString())
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const updateCustomerTaskAPI = (data: UpdateReq, token: string) => {
  const endPoint = APIPath.saleUpdateTask.replace(':id', data.id.toString())
  return withAPIAction<UpdateReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAction)
}

export const updateCustomerTaskStatusAPI = (
  data: UpdateStatusReq,
  token: string,
  isAgentChatSale: boolean = false,
) => {
  const path = isAgentChatSale ? APIPath.chatSaleUpdateTaskStatus : APIPath.saleUpdateTaskStatus
  const endPoint = path.replace(':id', data.id.toString())
  return withAPIAction<UpdateStatusReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateCustomerStatusAction)
}

export const assignTaskAPI = (data: AssignReq, token: string) => {
  const endPoint = APIPath.saleAssignTask.replace(':id', data.id.toString())
  return withAPIAction<AssignReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(assignTaskAction)
}

export const countAllTaskAPI = (data: CountReq, token: string) => {
  return withAPIAction<CountReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.countAllTab,
    data,
    token,
  })(countAllTabAction)
}

export const countMeTaskAPI = (data: CountReq, token: string, isAgentChatSale: boolean = false) => {
  const endPoint = isAgentChatSale ? APIPath.countChatSaleTab : APIPath.countMeTab
  return withAPIAction<CountReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(countMeTabAction)
}

export const getBranchListAPI = (data: BranchReq, token: string) => {
  return withAPIAction<BranchReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getBranchList,
    data,
    token,
  })(getBranchListAction)
}

export const getReasonListAPI = (data: ReasonReq, token: string) => {
  return withAPIAction<ReasonReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getReasonList,
    data,
    token,
  })(getReasonAction)
}

export const getPostcodeListAPI = (data: PostcodeReq, token: string) => {
  return withAPIAction<PostcodeReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getPostcodeList,
    data,
    token,
  })(getPostcodeListAction)
}

export const getDeliveyStaffListAPI = (data: DeliveryStaffReq, token: string) => {
  const endPoint = APIPath.getDeliveryStaffList.replace(':id', data.postcode.toString())
  return withAPIAction<DeliveryStaffReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getStaffListAction)
}
