import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Alert, Col, message, Row, Space } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  getCustomerTaskStatus,
  isFormRequired,
  isFormError,
  numberWithComma,
} from 'helpers/format'
import { DetailReq, LogReq, UpdateReq, UpdateStatusReq } from 'Store/chaiyo-customer/types'
import {
  selectDetail,
  selectDetailEvent,
  selectLogsData,
  selectTaskEvent,
} from 'Store/chaiyo-customer/selector'
import { clearEventAction } from 'Store/chaiyo-customer/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog, ConfirmQuestion } from 'Components/modal'
import { CustomerStatus, SaleTaskStatus, UserAgentType } from 'helpers/constanst'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppInput, MultiUploadForm } from 'Components/form'
import { StatusTag } from 'Components/utilities'
import {
  getCustomertDetailAPI,
  updateCustomerTaskStatusAPI,
  updateCustomerTaskAPI,
  getTaskLogsAPI,
} from 'Store/chaiyo-customer/api'
import { CheckOutlined, CloseOutlined, EditOutlined, MailOutlined } from '@ant-design/icons'
import Logs from './components/Logs'
import BranchLogs from '../ChaiyoCustomerBranchTask/components/Logs'
import LinkText from 'Components/text/LinkText'
import SendToBranchModal from './components/SendToBranchModal'
import { consoleLog } from 'helpers/utils'
import WarningTask from './components/WarningTask'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  id?: number
}

const CustomerTaskDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    id = 0,
    detail,
    detailEvent,
    loginData,
    userProfile,
    logsData,
  } = props

  const requireFeild = [
    'first_name',
    'last_name',
    'mobile',
    //'address',
  ]
  const initForm = {} as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [rejectDialog, setRejectDialog] = useState(false)
  const [approveDialog, setApproveDialog] = useState(false)
  const [toBranchDialog, setToBranchDialog] = useState(false)
  const [showLogDrawer, setShowLogDrawer] = useState(false)
  const [showBranchLogDrawer, setShowBranchLogDrawer] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const isTaskOpen = detail?.status === CustomerStatus.OPEN
  const isOwnTask = detail?.user_id === userProfile?.id
  const salePermission = userProfile?.profile?.partner_type === UserAgentType.CHATSALE
  const sentToBranch = logsData && logsData.find(item => item.status === SaleTaskStatus.TOBRANCH)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!visible) {
      setFormError([])
      setEditMode(false)
    }
    if (visible && id) {
      onGetDetail()
    }
  }, [visible])

  useEffect(() => {
    if (id > 0) {
      const form = {
        first_name: detail?.first_name,
        last_name: detail?.last_name,
        mobile: detail?.mobile,
        address: detail?.car_loan?.address,
        amount_limit: detail?.car_loan?.amount_limit || 0,
        amount_rate: detail?.car_loan?.amount_rate || 0,
        amount_redbook: detail?.car_loan?.amount_redbook || 0,
        car_paint: detail?.car_loan?.car_paint || '',
        car_registration: detail?.car_loan?.car_registration || '',
        id: detail?.id,
        images: detail?.car_loan?.images || [],
        status: detail?.status,
        car_brand: detail?.car_loan?.car_brand,
        car_model: detail?.car_loan?.car_model,
        car_year: detail?.car_loan?.car_year,
        finance_account_closing: detail?.car_loan?.finance_account_closing,
        financial_institution: detail?.car_loan?.financial_institution,
        note: detail?.car_loan?.note,
      } as UpdateReq
      setFormData({ ...form })
    }
    if (detail?.status === CustomerStatus.SENDCASE) {
      onGetDataLogs()
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onGetDetail = (): void => {
    const req: DetailReq = {
      id: id,
    }
    dispatch(getCustomertDetailAPI(req, loginData?.access_token || ''))
  }

  const onUpdate = (): void => {
    const req: UpdateReq = {
      ...formData,
      id: id,
    }
    dispatch(updateCustomerTaskAPI(req, loginData?.access_token || ''))
  }

  const onGetDataLogs = (): void => {
    const req: LogReq = {
      id: id,
    }
    dispatch(getTaskLogsAPI(req, loginData?.access_token || ''))
  }

  const onUpdateStatus = (status: CustomerStatus, reason: string): void => {
    const req: UpdateStatusReq = {
      id,
      status,
      reason,
    }
    dispatch(updateCustomerTaskStatusAPI(req, loginData?.access_token || ''))
  }

  const onCancel = (reason: string): void => {
    onUpdateStatus(CustomerStatus.CANCEL, reason)
  }

  const onClose = (): void => {
    onUpdateStatus(CustomerStatus.SENDCASE, '')
  }

  const onSave = () => {
    if (!validateForm()) {
      message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
      return
    }
    setEditMode(false)
    onUpdate()
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    consoleLog('onEventSuccess')
    clearEvent()
    onGetDetail()
    setRejectDialog(false)
    setApproveDialog(false)
    setToBranchDialog(false)
    onSuccess()
  }

  const FooterContent = (
    <Space size="middle" align="baseline">
      {/* <PrimaryButton text="บันทึกข้อมูล" btnType="Cancel" onClick={onSave} /> */}
      <PrimaryButton
        text="ยกเลิกเคส"
        icon={<CloseOutlined />}
        btnType="Reject"
        onClick={() => setRejectDialog(true)}
      />
      {!salePermission && (
        <PrimaryButton
          text="ส่งเคสไปยัง Sale"
          btnType="Approve"
          icon={<CheckOutlined />}
          onClick={() => setApproveDialog(true)}
        />
      )}
      {salePermission && (
        <PrimaryButton
          text="ส่งเคสดำเนินการต่อ"
          btnType="Primary"
          icon={<MailOutlined />}
          onClick={() => setToBranchDialog(true)}
        />
      )}
    </Space>
  )

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderStatus = (value: any) => {
    const result = getCustomerTaskStatus(value)
    return <StatusTag color={result.color} text={result.text} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const ConfirmRejectDailog = (
    <ConfirmQuestion
      title="ยกเลิกเคส"
      placeholder="กรุณาระบุเหตุผลที่ยกเลิกเคส"
      confirmBtnTxt="ยืนยันยกเลิก"
      isShow={rejectDialog}
      handleOk={(remark: string) => onCancel(remark)}
      handleCancel={() => setRejectDialog(false)}
      loading={event.IsLoading}
    />
  )

  const ConfirmApproveDailog = (
    <ConfirmDailog
      title="ยืนยันการส่งเคสไปยัง Telesale"
      description="คุณแน่ใจที่จะส่งเคสนี้ไปยัง Telesale หรือไม่?"
      confirmBtnTxt="ยืนยัน"
      isShow={approveDialog}
      handleOk={onClose}
      handleCancel={() => setApproveDialog(false)}
      loading={event.IsLoading}
    />
  )

  const ConfirmSendToBranch = (
    <SendToBranchModal isShow={toBranchDialog} onClose={() => setToBranchDialog(false)} id={id} />
  )

  const customerFormLeft = (
    <Row justify="space-between" gutter={[16, 1]}>
      <Col md={24} xs={24}>
        <AppInput
          name="first_name"
          label="ชื่อ"
          isRequired={isRequired('first_name')}
          isError={isError('first_name')}
          value={formData.first_name}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="last_name"
          label="นามสกุล"
          isRequired={isRequired('last_name')}
          isError={isError('last_name')}
          value={formData.last_name}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="mobile"
          label="เบอร์โทรศัพท์"
          placeHolder="ตัวอย่าง 0888888888"
          isRequired={isRequired('mobile')}
          isError={isError('mobile')}
          value={formData.mobile}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="address"
          label="ที่อยู่"
          isRequired={isRequired('address')}
          isError={isError('address')}
          value={formData.address}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="financial_institution"
          label="สถาบันการเงินเดิม"
          isRequired={isRequired('financial_institution')}
          isError={isError('financial_institution')}
          value={formData.financial_institution}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="finance_account_closing"
          label="ยอดปิดบัญชีไฟแนนซ์เดิม"
          isRequired={isRequired('finance_account_closing')}
          isError={isError('finance_account_closing')}
          value={formData.finance_account_closing}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppInput
          name="note"
          label="หมายเหตุ"
          isRequired={isRequired('note')}
          isError={isError('note')}
          value={formData.note}
          onChange={onUpdateData}
        />
      </Col>
    </Row>
  )

  const customerFormRight = (
    <Row justify="space-between" gutter={[16, 1]}>
      <Col span={24}>
        <AppInput
          name="amount_limit"
          label="วงเงินที่ลูกค้าต้องการ"
          isRequired={isRequired('amount_limit')}
          isError={isError('amount_limit')}
          value={formData.amount_limit}
          onChange={onUpdateData}
          type="number"
        />
      </Col>
      <Col span={24}>
        <AppInput
          name="amount_rate"
          label="อัตราผ่อนที่ชำระได้ /เดือน"
          isRequired={isRequired('amount_rate')}
          isError={isError('amount_rate')}
          value={formData.amount_rate}
          onChange={onUpdateData}
          type="number"
        />
      </Col>
      <Col span={24}>
        <AppInput
          name="car_paint"
          label="สีรถ"
          isRequired={isRequired('car_paint')}
          isError={isError('car_paint')}
          value={formData.car_paint}
          onChange={onUpdateData}
        />
      </Col>
      <Col span={24}>
        <AppInput
          name="car_registration"
          label="ทะเบียน"
          isRequired={isRequired('car_registration')}
          isError={isError('car_registration')}
          value={formData.car_registration}
          onChange={onUpdateData}
        />
      </Col>
      <Col span={24}>
        <MultiUploadForm
          label="รูปภาพรถยนต์"
          description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
          token={''}
          onUpdateData={onUpdateData}
          files={formData.images}
          listType="picture-card"
          name="images"
          readOnly={false}
          maxFileSize={5000}
        />
      </Col>
    </Row>
  )

  const getStatusInfo = (status: string) => {
    if (detail?.status === CustomerStatus.CANCEL) {
      return renderInfo('เหตุผลที่ยกเลิก', <RedText>{detail?.reason || ''}</RedText>)
    }

    if (detail?.status === CustomerStatus.SENDCASE && sentToBranch) {
      return (
        <div>
          {renderInfo('', <LinkStyle onClick={() => setShowLogDrawer(true)}>ตรวจสอบสถานะ</LinkStyle>, 15)}
          {renderInfo(
            '',
            <LinkStyle onClick={() => setShowBranchLogDrawer(true)}>ตรวจสอบสถานะจากสาขา</LinkStyle>,
            15,
          )}
        </div>
      )
    }

    if (detail?.status === CustomerStatus.SENDCASE) {
      return renderInfo('', <LinkStyle onClick={() => setShowLogDrawer(true)}>ตรวจสอบสถานะ</LinkStyle>)
    }

    return ''
  }

  const taskInfo = (
    <BoxWrapper title="รายละเอียดข้อมูล">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('หมายเลขเคส', detail?.ams_case_no), null)}
        {renderInfoRow(
          renderInfo('วันที่สร้างข้อมูล', formatDateTime(detail?.created_at || '')),
          renderInfo('สถานะ', renderStatus(detail?.status || '')),
        )}
        {renderInfoRow(
          renderInfo(
            'ผู้รับผิดชอบ',
            `${detail?.user?.profile?.first_name} ${detail?.user?.profile?.last_name} (ทีม ${detail?.agent
              ?.agent_name || '-'})`,
          ),
          getStatusInfo(detail?.status || ''),
        )}
        {renderInfoRow(renderInfo('QR ที่สแกนเข้ามา', detail?.qr_name || '-'), null)}
      </Row>
    </BoxWrapper>
  )

  const carInfo = (
    <BoxWrapper title="ข้อมูลรถยนต์">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ยี่ห้อรถยนต์', detail?.car_loan?.car_brand || ''),
          renderInfo('รุ่นรถ', detail?.car_loan?.car_model),
        )}
        {renderInfoRow(
          renderInfo('ปีที่จดทะเบียน', detail?.car_loan?.car_year),
          renderInfo('ราคาประเมิน', `${numberWithComma(detail?.car_loan?.amount_redbook, true)} บาท`),
        )}
      </Row>
    </BoxWrapper>
  )

  const editButton = () => {
    return (
      <PrimaryButton
        text="แก้ไขข้อมูล"
        icon={<EditOutlined />}
        btnType="Primary2"
        onClick={() => setEditMode(true)}
      />
    )
  }

  const saveButton = () => {
    return (
      <Space>
        <PrimaryButton text="ยกเลิก" btnType="Cancel" onClick={() => setEditMode(false)} />
        <PrimaryButton text="บันทึกข้อมูล" onClick={onSave} />
      </Space>
    )
  }

  const customerForm = (
    <BoxWrapper
      title="ข้อมูลลูกค้า"
      right={isTaskOpen && isOwnTask && (!editMode ? editButton() : saveButton())}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col md={12} xs={24}>
          {customerFormLeft}
        </Col>
        <Col md={12} xs={24}>
          {customerFormRight}
        </Col>
        {/* <Col>
          <Space>
            <PrimaryButton text="ยกเลิก" btnType="Cancel" onClick={() => setEditMode(false)} />
            <PrimaryButton text="บันทึกข้อมูล" onClick={onSave} />
          </Space>
        </Col> */}
      </Row>
    </BoxWrapper>
  )

  const customerInfo = (
    <BoxWrapper
      title="ข้อมูลลูกค้า"
      right={isTaskOpen && isOwnTask && (!editMode ? editButton() : saveButton())}
    >
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ชื่อ', detail?.first_name || ''),
          renderInfo('นามสกุล', detail?.last_name || ''),
        )}
        {renderInfoRow(
          renderInfo('เบอร์โทรศัพท์', detail?.mobile || ''),
          renderInfo('ที่อยู่', detail?.car_loan?.address || ''),
        )}
        {renderInfoRow(
          renderInfo(
            'วงเงินที่ลูกค้าต้องการ',
            `${numberWithComma(detail?.car_loan?.amount_limit || 0, true)} บาท`,
          ),
          renderInfo(
            'อัตราผ่อนที่ชำระได้ /เดือน',
            `${numberWithComma(detail?.car_loan?.amount_rate || 0, true)} บาท`,
          ),
        )}
        {renderInfoRow(
          renderInfo('สีรถ', detail?.car_loan?.car_paint || ''),
          renderInfo('ทะเบียน', detail?.car_loan?.car_registration || ''),
        )}
        {renderInfoRow(
          renderInfo('สถาบันการเงินเดิม', detail?.car_loan?.financial_institution || ''),
          renderInfo('ยอดปิดบัญชีไฟแนนซ์เดิม', detail?.car_loan?.finance_account_closing || ''),
        )}
        {renderInfoRow(renderInfo('หมายเหตุ', detail?.car_loan?.note || ''), null)}
        <Col span={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.car_loan?.images || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <Title>
          หมายเลขเคส : {detail?.ams_case_no} ({detail?.first_name} {detail?.last_name})
        </Title>
      </div>
    </Space>
  )

  const LogsDrawer = <Logs isShow={showLogDrawer} onClose={() => setShowLogDrawer(false)} id={id} />
  const BranchLogsDrawer = (
    <BranchLogs isShow={showBranchLogDrawer} onClose={() => setShowBranchLogDrawer(false)} id={id} />
  )
  const hasWarning = detail?.warning_messages && detail?.warning_messages?.length > 0

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={isTaskOpen && !editMode && isOwnTask && FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <Row gutter={[16, 16]}>
        {hasWarning && (
          <Col md={24} xs={24}>
            <WarningTask warningMessages={detail?.warning_messages || []} />
          </Col>
        )}
        <Col md={24} xs={24}>
          {taskInfo}
        </Col>
        <Col md={24} xs={24}>
          {carInfo}
        </Col>
        {/* <Col span={24}>{isTaskOpen ? customerForm : customerInfo}</Col> */}
        <Col span={24}>{editMode ? customerForm : customerInfo}</Col>
      </Row>
      {ConfirmRejectDailog}
      {ConfirmApproveDailog}
      {LogsDrawer}
      {ConfirmSendToBranch}
      {BranchLogsDrawer}
    </DetailDrawer>
  )
}

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const CreatedDate = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const LinkStyle = styled(LinkText)`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

const RedText = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.error};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectTaskEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    userProfile: selectUserProfile(state),
    logsData: selectLogsData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CustomerTaskDetail)
