//status
export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum StatusText {
  ACTIVE = 'เปิดใช้งาน',
  INACTIVE = 'ปิดใช้งาน',
}

export const menuStatus = [
  {
    name: StatusText.ACTIVE,
    value: Status.ACTIVE,
  },
  {
    name: StatusText.INACTIVE,
    value: Status.INACTIVE,
  },
]

export enum LoginStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum LoginStatusText {
  ONLINE = 'ออนไลน์',
  OFFLINE = 'ออฟไลน์',
}

export const menuLoginStatus = [
  {
    name: LoginStatusText.ONLINE,
    value: LoginStatus.ONLINE,
  },
  {
    name: LoginStatusText.OFFLINE,
    value: LoginStatus.OFFLINE,
  },
]

export enum Config {
  TENMB_UPLOAD_FILE_SIZE_BYTES = 10000000, // 10MB
  FIVEMB_UPLOAD_FILE_SIZE_BYTES = 5000000, // 5MB
  ONEMB_UPLOAD_FILE_SIZE_BYTES = 1000000, // 1MB
  FIVEKB_UPLOAD_FILE_SIZE_BYTES = 500000, // 500KB
}

export const monthNames = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  th: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
}

export enum WithdrawStatus {
  PROCESSTING = 'processing',
  APPROVED = 'approved',
  BANK_PROCESSTING = 'bank_processing',
  TRANSFERED = 'transfered',
  REJECTED = 'reject',
}

//config constant for AGENT
export enum AgentType {
  INDIVIDUAL = 'individual',
  COPORATE = 'corporate',
}

export enum AgentTypeText {
  INDIVIDUAL = 'ตัวแทนอิสระ',
  COPORATE = 'ตัวแทนบริษัทห้างร้าน',
}

export enum AgentStatus {
  APPROVE = 'approve',
  PENDING = 'pending',
  REJECT = 'reject',
}

export enum AgentStatusText {
  APPROVE = 'อนุมัติ',
  PENDING = 'รออนุมัติ',
  REJECT = 'ไม่อนุมัติ',
  ALL = 'ทั้งหมด',
}

export const menuAgentStatus = [
  {
    value: '',
    name: 'ทั้งหมด',
  },
  {
    value: AgentStatus.PENDING,
    name: AgentStatusText.PENDING,
  },
  {
    value: AgentStatus.APPROVE,
    name: AgentStatusText.APPROVE,
  },
  {
    value: AgentStatus.REJECT,
    name: AgentStatusText.REJECT,
  },
]

export const passwordFormatConfig = [
  {
    name: 'อักษรภาษาอังกฤษตัวพิมพ์ใหญ่ (A-Z)',
    value: 'uppercase',
  },
  {
    name: 'อักษรภาษาอังกฤษตัวพิมพ์เล็ก (a-z)',
    value: 'lowercase',
  },
  {
    name: 'ตัวเลขอย่างน้อย 1 ตัว (0-9)',
    value: 'number',
  },
  {
    name: 'สัญลักษณ์พิเศษอย่างน้อย 1 ตัว (-!$%@^&*#()_+|~=`{}[]:”;’<>?,./)',
    value: 'sign',
  },
  {
    name: 'ความยาวขั้นต่ำ 8 ตัวอักษร',
    value: 'length',
  },
]

export const agentDocs = [
  {
    name: 'สำเนาบัตรประชาชน หรือ สำเนาหนังสือจดทะเบียนนิติบุคคล',
    key: 'document_personal_id',
  },
  {
    name: 'สำเนาหน้าบัญชีออมทรัพย์ เพื่อใช้รับค่าตอบแทน',
    key: 'document_bank',
  },
  {
    name: 'สำเนาทะเบียนบ้าน',
    key: 'document_copy_of_house',
  },
  {
    name: 'เอกสารการยืนยันอาชีพ',
    key: 'document_occupational',
  },
  {
    name: 'รูปถ่าย',
    key: 'document_personal_picture',
  },
]

export const menuPrefix = [
  {
    value: 'นาย',
    name: 'นาย',
  },
  {
    value: 'นาง',
    name: 'นาง',
  },
  {
    value: 'นางสาว',
    name: 'นางสาว',
  },
]

export const menuSex = [
  {
    value: 'ชาย',
    name: 'ชาย',
  },
  {
    value: 'หญิง',
    name: 'หญิง',
  },
]

export enum UserType {
  AGENT = 'agent',
  ADMIN = 'admin',
  SALE = 'sale',
  ADMINQR = 'create_qr',
  QRPARTNER = 'agent_qr',
}

export enum CustomerStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  CLOSE = 'close',
  CANCEL = 'cancel',
  SENDCASE = 'send_to_sale',
}

export enum CustomerStatusText {
  DRAFT = 'draft',
  OPEN = 'To Sale',
  CLOSE = 'ปิดเคส',
  CANCEL = 'Cancel',
  SENDCASE = 'Sent to sale',
}

export const menuCustomerTaskStatus = [
  {
    value: '',
    name: 'ทั้งหมด',
  },
  {
    value: CustomerStatus.OPEN,
    name: CustomerStatusText.OPEN,
  },
  {
    value: CustomerStatus.CLOSE,
    name: CustomerStatusText.CLOSE,
  },
  {
    value: CustomerStatus.CANCEL,
    name: CustomerStatusText.CANCEL,
  },
]

export const menuChaiyoCustomerTaskStatus = [
  {
    value: '',
    name: 'All',
  },
  {
    value: CustomerStatus.OPEN,
    name: CustomerStatusText.OPEN,
  },
  {
    value: CustomerStatus.SENDCASE,
    name: CustomerStatusText.SENDCASE,
  },
  {
    value: CustomerStatus.CANCEL,
    name: CustomerStatusText.CANCEL,
  },
]

// Task type
export enum TaskType {
  INSURANCE = 'insure',
}

export enum TaskTypeText {
  INSURANCE = 'ประกันภัย',
}

export const menuGeetingMsgType = [
  {
    value: 'text',
    name: 'ข้อความ',
  },
  {
    value: 'image',
    name: 'รูปภาพ',
  },
  {
    value: 'link',
    name: 'รูปภาพ+ลิ้งค์',
  },
  {
    value: 'auto',
    name: 'รูปภาพ+ระบบตอบอัตโนมัติ',
  },
]

export const menuAnswerType = [
  {
    value: 'text',
    name: 'ข้อความ',
  },
  {
    value: 'image',
    name: 'รูปภาพ',
  },
]

export const menuMainCustomerTaskStatus = [
  {
    value: '1',
    name: 'มอบหมายให้ฉัน',
  },
  {
    value: '2',
    name: 'ทั้งหมด',
  },
]

//sale tab

export enum SaleTaskStatus {
  INQUE = 'in_queue',
  ONPROCESS = 'on_process',
  TOBRANCH = 'to_branch',
  TODELIVERY = 'to_delivery',
  REJECTED = 'reject',
  CLOSED = 'close',
  CANCEL = 'cancel',
}

export enum SaleTaskStatusText {
  INQUE = 'In Queue',
  ONPROCESS = 'On Process',
  TOBRANCH = 'To Branch',
  TODELIVERY = 'To Delivery',
  REJECTED = 'Reject',
  CLOSED = 'Booked',
  CANCEL = 'Cancel',
}

export const menuSaleTaskStatus = [
  {
    value: '',
    name: 'All',
  },
  {
    value: SaleTaskStatus.INQUE,
    name: SaleTaskStatusText.INQUE,
  },
  {
    value: SaleTaskStatus.ONPROCESS,
    name: SaleTaskStatusText.ONPROCESS,
  },
  {
    value: SaleTaskStatus.TOBRANCH,
    name: SaleTaskStatusText.TOBRANCH,
  },
  {
    value: SaleTaskStatus.TODELIVERY,
    name: SaleTaskStatusText.TODELIVERY,
  },
  {
    value: SaleTaskStatus.CLOSED,
    name: SaleTaskStatusText.CLOSED,
  },
  {
    value: SaleTaskStatus.REJECTED,
    name: SaleTaskStatusText.REJECTED,
  },
  {
    value: SaleTaskStatus.CANCEL,
    name: SaleTaskStatusText.CANCEL,
  },
]

export const menuAgentSaleTaskStatus = [
  {
    value: '',
    name: 'All',
  },
  {
    value: SaleTaskStatus.TOBRANCH,
    name: SaleTaskStatusText.TOBRANCH,
  },
  {
    value: SaleTaskStatus.TODELIVERY,
    name: SaleTaskStatusText.TODELIVERY,
  },
  {
    value: SaleTaskStatus.CLOSED,
    name: SaleTaskStatusText.CLOSED,
  },
  {
    value: SaleTaskStatus.REJECTED,
    name: SaleTaskStatusText.REJECTED,
  },
  {
    value: SaleTaskStatus.CANCEL,
    name: SaleTaskStatusText.CANCEL,
  },
]

//branch
export enum BranchTaskStatus {
  ONPROCESS = 'to_branch',
  REJECTED = 'reject',
  CLOSED = 'close',
  OPEN = 'open',
  CANCEL = 'cancel',
}

export enum BranchTaskStatusText {
  ONPROCESS = 'สาขาดำเนินการ',
  REJECTED = 'ปฏิเสธสินเชื่อ',
  CANCEL = 'ยกเลิกสินเชื่อ',
  CLOSED = 'อนุมัติสินเชื่อ',
  OPEN = 'รอดำเนินการ',
}

export const menuAgentBranchTaskStatus = [
  // {
  //   value: '',
  //   name: 'งานทั้งหมด',
  // },
  {
    value: BranchTaskStatus.ONPROCESS,
    name: BranchTaskStatusText.ONPROCESS,
  },
  {
    value: BranchTaskStatus.CLOSED,
    name: BranchTaskStatusText.CLOSED,
  },
  {
    value: BranchTaskStatus.REJECTED,
    name: BranchTaskStatusText.REJECTED,
  },
  {
    value: BranchTaskStatus.CANCEL,
    name: BranchTaskStatusText.CANCEL,
  },
]

export const menuSaleAllTaskStatus = [
  {
    value: '',
    name: 'All',
  },
]

export const menuBankAccount = [
  {
    name: 'ธนาคารไทยพาณิชย์',
    value: 'SCB',
  },
  {
    name: 'ธนาคารกรุงเทพ',
    value: 'BBL',
  },
  {
    name: 'ธนาคารกรุงไทย',
    value: 'KTB',
  },
  {
    name: 'ธนาคารกสิกรไทย',
    value: 'KBANK',
  },
  {
    name: 'ธนาคารทหารไทย',
    value: 'TMB',
  },
  {
    name: 'ธนาคารทิสโก้',
    value: 'TISCO',
  },
  {
    name: 'ธนาคารออมสิน',
    value: 'GSB',
  },
  {
    name: 'ธนาคารอาคารสงเคราะห์',
    value: 'GHB',
  },
  {
    name: 'ธนาคารกรุงศรีอยุธยา',
    value: 'BAY',
  },
  {
    name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    value: 'BAAC',
  },
  {
    name: 'ธนาคารฮ่องกงและเซี่ยงไฮ้แบงกิ้ง',
    value: 'HSBC',
  },
]

export const menuNoneScbBankAccount = [
  {
    name: 'ธนาคารกรุงเทพ',
    value: 'BBL',
  },
  {
    name: 'ธนาคารกรุงไทย',
    value: 'KTB',
  },
  {
    name: 'ธนาคารกสิกรไทย',
    value: 'KBANK',
  },
  {
    name: 'ธนาคารทหารไทย',
    value: 'TMB',
  },
  {
    name: 'ธนาคารทิสโก้',
    value: 'TISCO',
  },
  {
    name: 'ธนาคารออมสิน',
    value: 'GSB',
  },
  {
    name: 'ธนาคารอาคารสงเคราะห์',
    value: 'GHB',
  },
  {
    name: 'ธนาคารกรุงศรีอยุธยา',
    value: 'BAY',
  },
  {
    name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    value: 'BAAC',
  },
  {
    name: 'ธนาคารฮ่องกงและเซี่ยงไฮ้แบงกิ้ง',
    value: 'HSBC',
  },
]
//Partner QR

export enum AgentApproveStatus {
  APPROVE = 'approve',
  PENDING = 'pending',
  REJECT = 'reject',
}

export enum AgentApproveStatusText {
  APPROVE = 'อนุมัติ',
  PENDING = 'รออนุมัติ',
  REJECT = 'ไม่อนุมัติ',
  ALL = 'ทั้งหมด',
}

export const menuPartnerQr = [
  {
    value: '',
    name: 'ทั้งหมด',
  },
  {
    value: AgentApproveStatus.PENDING,
    name: AgentApproveStatusText.PENDING,
  },
  {
    value: AgentApproveStatus.APPROVE,
    name: AgentApproveStatusText.APPROVE,
  },
  {
    value: AgentApproveStatus.REJECT,
    name: AgentApproveStatusText.REJECT,
  },
]

//user agent type

//Partner QR
export enum UserAgentType {
  CHAT = 'chat',
  NOCHAT = 'not_chat',
  CHATSALE = 'chat_sale',
}

export enum UserAgentTypeText {
  CHAT = 'ตอบแชท',
  NOCHAT = 'ไม่ตอบแชท',
  CHATSALE = 'ตอบแชท + Sale',
}

export const menuChaiyoUserAgentType = [
  {
    value: UserAgentType.CHAT,
    name: UserAgentTypeText.CHAT,
  },
  {
    value: UserAgentType.NOCHAT,
    name: UserAgentTypeText.NOCHAT,
  },
  {
    value: UserAgentType.CHATSALE,
    name: UserAgentTypeText.CHATSALE,
  },
]

export const menuOPDUserAgentType = [
  {
    value: UserAgentType.CHAT,
    name: UserAgentTypeText.CHAT,
  },
  {
    value: UserAgentType.NOCHAT,
    name: UserAgentTypeText.NOCHAT,
  },
]

export const menuBranchReason = [
  {
    value: 'อยู่ระหว่างประสานงานลูกค้า - ลูกค้าไม่รับสาย',
    name: 'อยู่ระหว่างประสานงานลูกค้า - ลูกค้าไม่รับสาย',
  },
  {
    value: 'อยู่ระหว่างประสานงานลูกค้า - เบอร์ติดต่อไม่ถูกต้อง',
    name: 'อยู่ระหว่างประสานงานลูกค้า - เบอร์ติดต่อไม่ถูกต้อง',
  },
  {
    value: 'อยู่ระหว่างประสานงานลูกค้า - ลูกค้าไม่สะดวกคุย ให้ติดต่อกลับภายหลัง',
    name: 'อยู่ระหว่างประสานงานลูกค้า - ลูกค้าไม่สะดวกคุย ให้ติดต่อกลับภายหลัง',
  },
  {
    value: 'อยู่ระหว่างประสานงานลูกค้า - อยู่ระหว่างเตรียมเอกสาร',
    name: 'อยู่ระหว่างประสานงานลูกค้า - อยู่ระหว่างเตรียมเอกสาร',
  },
  {
    value: 'อยู่ระหว่างประสานงานลูกค้า - นัดหมายลูกค้าเข้าสาขา',
    name: 'อยู่ระหว่างประสานงานลูกค้า - นัดหมายลูกค้าเข้าสาขา',
  },
]

export const menuBranchApproveReason = [
  {
    value: 'อนุมัติ - อนุมัติสินเชื่อ',
    name: 'อนุมัติ - อนุมัติสินเชื่อ',
  },
]

export const menuBranchRejectReason = [
  {
    value: 'ปฏิเสธสินเชื่อ - คุณสมบัติไม่เข้าเงื่อนไข',
    name: 'ปฏิเสธสินเชื่อ - คุณสมบัติไม่เข้าเงื่อนไข',
  },
  {
    value: 'ปฏิเสธสินเชื่อ - คุณสมบัติหลักประกันไม่เข้าเงื่อนไข',
    name: 'ปฏิเสธสินเชื่อ - คุณสมบัติหลักประกันไม่เข้าเงื่อนไข',
  },
  {
    value: 'ปฏิเสธสินเชื่อ - ลูกค้าไม่ต้องการใช้สินเชื่อ',
    name: 'ปฏิเสธสินเชื่อ - ลูกค้าไม่ต้องการใช้สินเชื่อ',
  },
]
