import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  customerList: {
    data: {
      data: [],
      meta: cloneDeep(initMeta),
    },
    event: cloneDeep(initEvent),
  },
  assignTaskEvent: cloneDeep(initEvent),
  customerDetail: {
    detail: undefined,
    event: cloneDeep(initEvent),
  },
  allTabCountData: {
    all: 0,
    cancel: 0,
    in_queue: 0,
    on_process: 0,
    to_branch: 0,
  },
  meTabCountData: {
    all: 0,
    cancel: 0,
    in_queue: 0,
    on_process: 0,
    to_branch: 0,
  },
  taskEvent: cloneDeep(initEvent),
  branchData: {
    data: [],
    event: cloneDeep(initEvent),
  },
  reasonData: {
    data: [],
    event: cloneDeep(initEvent),
  },
  postcodeData: {
    data: [],
    event: cloneDeep(initEvent),
  },
  deliveryStaffData: {
    data: [],
    event: cloneDeep(initEvent),
  },
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.getListAction.request):
        draft.customerList.event = cloneDeep(initEvent)
        draft.customerList.event.IsLoading = true
        draft.customerList.data = {
          data: [],
          meta: cloneDeep(initMeta),
        }
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitSuccess = true
        draft.customerList.data.data = response.data
        if (response.meta) {
          draft.customerList.data.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getDetailAction.request):
        draft.customerDetail.event = cloneDeep(initEvent)
        draft.customerDetail.event.IsLoading = true
        draft.customerDetail.detail = undefined
        return draft
      case getType(actions.getDetailAction.success):
        response = action.payload
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitSuccess = true
        draft.customerDetail.detail = response.data
        return draft
      case getType(actions.getDetailAction.failure):
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.updateCustomerStatusAction.request):
      case getType(actions.updateAction.request):
        draft.taskEvent = cloneDeep(initEvent)
        draft.taskEvent.IsLoading = true
        return draft
      case getType(actions.updateCustomerStatusAction.success):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = undefined
        draft.taskEvent.SubmitSuccess = true
        draft.taskEvent.MessageSuccess = 'ทำรายการสำเร็จ'
        return draft
      case getType(actions.updateAction.success):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = undefined
        draft.taskEvent.SubmitSuccess = true
        draft.taskEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        return draft
      case getType(actions.updateCustomerStatusAction.failure):
      case getType(actions.updateAction.failure):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.assignTaskAction.request):
        draft.assignTaskEvent = cloneDeep(initEvent)
        draft.assignTaskEvent.IsLoading = true
        return draft
      case getType(actions.assignTaskAction.success):
        draft.assignTaskEvent.IsLoading = false
        draft.assignTaskEvent.SubmitError = undefined
        draft.assignTaskEvent.SubmitSuccess = true
        draft.assignTaskEvent.MessageSuccess = 'รับงานสำเร็จ! ตรวจสอบงานใหม่ได้ใน "มอบหมายให้ฉัน"'
        return draft
      case getType(actions.assignTaskAction.failure):
        draft.assignTaskEvent.IsLoading = false
        draft.assignTaskEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.countAllTabAction.request):
        return draft
      case getType(actions.countAllTabAction.success):
        draft.allTabCountData = action.payload.data
        return draft
      case getType(actions.countAllTabAction.failure):
        return draft
      case getType(actions.countMeTabAction.request):
        return draft
      case getType(actions.countMeTabAction.success):
        draft.meTabCountData = action.payload.data
        return draft
      case getType(actions.countMeTabAction.failure):
        return draft

      case getType(actions.getBranchListAction.request):
        draft.branchData.event = cloneDeep(initEvent)
        draft.branchData.event.IsLoading = true
        return draft
      case getType(actions.getBranchListAction.success):
        response = action.payload
        draft.branchData.event.IsLoading = false
        draft.branchData.event.SubmitSuccess = true
        draft.branchData.data = response.data || []
        return draft
      case getType(actions.getBranchListAction.failure):
        draft.branchData.event.IsLoading = false
        draft.branchData.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getReasonAction.request):
        draft.reasonData.event = cloneDeep(initEvent)
        draft.reasonData.event.IsLoading = true
        return draft
      case getType(actions.getReasonAction.success):
        response = action.payload
        draft.reasonData.event.IsLoading = false
        draft.reasonData.event.SubmitSuccess = true
        draft.reasonData.data = response.data || []
        return draft
      case getType(actions.getReasonAction.failure):
        draft.reasonData.event.IsLoading = false
        draft.reasonData.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getPostcodeListAction.request):
        draft.postcodeData.event = cloneDeep(initEvent)
        draft.postcodeData.event.IsLoading = true
        return draft
      case getType(actions.getPostcodeListAction.success):
        response = action.payload
        draft.postcodeData.event.IsLoading = false
        draft.postcodeData.event.SubmitSuccess = true
        draft.postcodeData.data = response.data || []
        return draft
      case getType(actions.getPostcodeListAction.failure):
        draft.postcodeData.event.IsLoading = false
        draft.postcodeData.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getStaffListAction.request):
        draft.deliveryStaffData.event = cloneDeep(initEvent)
        draft.deliveryStaffData.event.IsLoading = true
        draft.deliveryStaffData.data = []
        return draft
      case getType(actions.getStaffListAction.success):
        response = action.payload
        draft.deliveryStaffData.event.IsLoading = false
        draft.deliveryStaffData.event.SubmitSuccess = true
        draft.deliveryStaffData.data = response.data || []
        return draft
      case getType(actions.getStaffListAction.failure):
        draft.deliveryStaffData.event.IsLoading = false
        draft.deliveryStaffData.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.clearEventAction):
        draft.assignTaskEvent = cloneDeep(initEvent)
        draft.taskEvent = cloneDeep(initEvent)
        draft.branchData.event = cloneDeep(initEvent)
        draft.reasonData.event = cloneDeep(initEvent)
        draft.postcodeData.event = cloneDeep(initEvent)
        draft.deliveryStaffData.event = cloneDeep(initEvent)

        return draft

      default:
        return state
    }
  })
}
