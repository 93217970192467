import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import Theme, { MyTheme } from 'Components/common/Theme'
import { Card, Col, message, Radio, Row, Space, Statistic } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  isFormRequired,
  isFormError,
  numberWithComma,
  getSaleTaskStatus,
  formatDateWithoutTime,
  toDateQuery,
  getBranchMenuSelect,
  getMenuSelect,
  getReasonhMenuSelect,
  getPostcodeMenuSelect,
  getDeliveryStaffMenuSelect,
  isAfter,
} from 'helpers/format'
import moment from 'moment'
import {
  AssignReq,
  BranchReq,
  DeliveryStaffReq,
  DetailReq,
  PostcodeReq,
  ReasonReq,
  UpdateReq,
  UpdateStatusReq,
} from 'Store/sale-task/types'
import {
  selectAssignTaskEvent,
  selectBranchList,
  selectDeliveryStaffList,
  selectDetail,
  selectDetailEvent,
  selectPostcodeList,
  selectReasonList,
  selectTaskEvent,
} from 'Store/sale-task/selector'
import { clearEventAction } from 'Store/sale-task/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog, ConfirmQuestion, ConfirmWithChild } from 'Components/modal'
import { SaleTaskStatus } from 'helpers/constanst'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppDateItem, AppInput, AppSelect, AppTextArea, MultiUploadForm } from 'Components/form'
import { StatusTag } from 'Components/utilities'
import {
  updateCustomerTaskStatusAPI,
  updateCustomerTaskAPI,
  assignTaskAPI,
  getBranchListAPI,
  getReasonListAPI,
  getPostcodeListAPI,
  getDeliveyStaffListAPI,
} from 'Store/sale-task/api'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MailOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import Logs from '../../Agent/ChaiyoCustomerTask/components/Logs'
import BranchLogs from '../../Agent/ChaiyoCustomerBranchTask/components/Logs'
import { getAllCarloanDetailAPI, getMeCarloanDetailAPI } from 'Store/sale-task/api'
import { TaskSaleData } from 'Store/sale-task/types'
import { selectBrandList, selectModelList, selectYearList } from 'Store/chaiyo-customer/selector'
import { BrandReq, BrandSlugReq, YearData } from 'Store/chaiyo-customer/types'
import { getBrandBySlugListAPI, getBrandListAPI } from 'Store/chaiyo-customer/api'
import { getYearByModelDataAction, resetBrandDataAction } from 'Store/chaiyo-customer/actions'
import { appConfig } from 'config'
import { consoleLog } from 'helpers/utils'
import WarningTask from 'Pages/Agent/ChaiyoCustomerTask/components/WarningTask'
import LinkText from 'Components/text/LinkText'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  id?: number
  isMe: boolean
  isAgentChatSale?: boolean
}

const CustomerTaskDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    id = 0,
    detail,
    detailEvent,
    loginData,
    isMe,
    assignTaskEvent,
    branchList,
    brandList,
    modelList,
    yearList,
    reasonList,
    isAgentChatSale = false,
    postcodeList,
    staffList,
  } = props

  const branchRequireFeild = [
    'branch_customer',
    'branch_customer_date',
    'first_name',
    'last_name',
    'mobile',
    'car_brand',
    'car_model',
    'car_year',
    'amount_redbook',
    'amount_limit',
    'amount_rate',
    'car_paint',
    'car_registration',
    'branch_customer_reason',
  ]

  const toDeliveryRequireFeild = [
    'first_name',
    'last_name',
    'mobile',
    'car_brand',
    'car_model',
    'car_year',
    'amount_redbook',
    'amount_limit',
    'amount_rate',
    'car_paint',
    'car_registration',
    'delivery_customer_date',
    'delivery_customer_email',
    'delivery_customer_postcode',
    'delivery_customer_reason',
    'delivery_customer_staff_id',
    'delivery_customer_name',
  ]
  const initForm = {} as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [rejectDialog, setRejectDialog] = useState(false)
  const [closedDialog, setClosedDialog] = useState(false)
  const [cancelDialog, setCancelDialog] = useState(false)
  const [approveDialog, setApproveDialog] = useState(false)
  const [showLogDrawer, setShowLogDrawer] = useState(false)
  const [reasonSelected, setReasonSelected] = useState('')
  const [reasonInput, setReasonInput] = useState('')
  const [editMode, setEditMode] = useState(false)
  const editable = detail?.task_sale?.status === SaleTaskStatus.ONPROCESS
  const [showBranchLogDrawer, setShowBranchLogDrawer] = useState(false)
  const [activeYear, setActiveYear] = useState(undefined as YearData | undefined)
  // const [nextStatus, setNextStatus] = useState('' as SaleTaskStatus.TOBRANCH | SaleTaskStatus.TODELIVERY)
  const dispatch = useDispatch()
  const requireFeild =
    formData.task_type === SaleTaskStatus.TOBRANCH ? branchRequireFeild : toDeliveryRequireFeild
  const taskTypeName = formData.task_type === SaleTaskStatus.TOBRANCH ? 'สาขา' : ' Delivery '

  useEffect(() => {
    if (!visible) {
      setFormError([])
      setEditMode(false)
    }
    if (visible && id) {
      onGetDetail()
    }
  }, [visible])

  const initFormData = () => {
    const form = {
      images: detail?.car_loan?.images || [],
      documents: detail?.task_sale?.documents || [],
      branch_customer: detail?.task_sale?.branch_customer || '',
      branch_customer_date: detail?.task_sale?.branch_customer_date || '',
      branch_customer_note: detail?.task_sale?.branch_customer_note || '',
      branch_customer_email: detail?.task_sale?.branch_customer_email || '',
      branch_customer_reason: detail?.task_sale?.branch_customer_reason || 'TO BRANCH - ลูกค้าพร้อมเข้าสาขา',
      address: detail?.car_loan?.address,
      amount_limit: detail?.car_loan?.amount_limit,
      amount_rate: detail?.car_loan?.amount_rate,
      amount_redbook: detail?.car_loan?.amount_redbook,
      car_brand: detail?.car_loan?.car_brand,
      car_model: detail?.car_loan?.car_model,
      car_paint: detail?.car_loan?.car_paint,
      car_registration: detail?.car_loan?.car_registration,
      car_year: detail?.car_loan?.car_year,
      first_name: detail?.first_name,
      last_name: detail?.last_name,
      mobile: detail?.mobile,
      task_type: detail?.task_sale?.task_type || SaleTaskStatus.TOBRANCH,
      delivery_customer_date: detail?.task_sale?.delivery_customer_date,
      delivery_customer_email: detail?.task_sale?.delivery_customer_email,
      delivery_customer_name: detail?.task_sale?.delivery_customer_name,
      delivery_customer_note: detail?.task_sale?.delivery_customer_note,
      delivery_customer_postcode: detail?.task_sale?.delivery_customer_postcode,
      delivery_customer_reason:
        detail?.task_sale?.delivery_customer_reason || 'TO DELIVERY – ลูกค้าพร้อมรับบริการ',
      delivery_customer_staff_id: detail?.task_sale?.delivery_customer_staff_id,
      reason: detail?.task_sale?.reason,
      finance_account_closing: detail?.car_loan?.finance_account_closing,
      financial_institution: detail?.car_loan?.financial_institution,
      note: detail?.car_loan?.note,
    } as UpdateReq
    setFormData({ ...form })
  }

  useEffect(() => {
    if (id > 0) {
      initFormData()
      onGetReasonListAPI()
      onGetPostcodeListAPI()
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onGetDetail = (): void => {
    const req: DetailReq = {
      id: id,
    }
    if (isMe) {
      dispatch(getMeCarloanDetailAPI(req, loginData?.access_token || '', isAgentChatSale))
    } else {
      dispatch(getAllCarloanDetailAPI(req, loginData?.access_token || ''))
    }
  }

  const onGetBranchListAPI = () => {
    const req: BranchReq = {}
    dispatch(getBranchListAPI(req, loginData?.access_token || ''))
  }

  const onGetReasonListAPI = () => {
    const req: ReasonReq = {}
    dispatch(getReasonListAPI(req, loginData?.access_token || ''))
  }

  const onGetPostcodeListAPI = () => {
    const req: PostcodeReq = {}
    dispatch(getPostcodeListAPI(req, loginData?.access_token || ''))
  }

  const onGetStaffByPostcodeAPI = (postcode: string) => {
    const req: DeliveryStaffReq = {
      postcode,
    }
    dispatch(getDeliveyStaffListAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (visible && formData.delivery_customer_postcode) {
      onGetStaffByPostcodeAPI(formData.delivery_customer_postcode)
    }
  }, [formData.delivery_customer_postcode])

  const onUpdate = (): void => {
    const req: UpdateReq = {
      ...formData,
      id: id,
    }

    if (formData.task_type === SaleTaskStatus.TOBRANCH || formData.branch_customer_date) {
      req.branch_customer_date = toDateQuery(formData.branch_customer_date)
    }

    if (formData.task_type === SaleTaskStatus.TODELIVERY || formData.delivery_customer_date) {
      req.delivery_customer_date = toDateQuery(formData.delivery_customer_date)
    }

    dispatch(updateCustomerTaskAPI(req, loginData?.access_token || ''))
  }

  const onUpdateStatus = (status: SaleTaskStatus, reason?: string, note?: string): void => {
    // consoleLog('onUpdateStatus', status)
    const req: UpdateStatusReq = {
      status,
      ...formData,
      reason: reason || '',
      note: note || '',
      id,
    }

    if (formData.branch_customer_date) {
      req.branch_customer_date = toDateQuery(formData.branch_customer_date)
    }

    if (formData.delivery_customer_date) {
      req.delivery_customer_date = toDateQuery(formData.delivery_customer_date)
    }

    dispatch(updateCustomerTaskStatusAPI(req, loginData?.access_token || '', isAgentChatSale))
  }

  const onAssignTask = (): void => {
    const req: AssignReq = {
      id,
    }
    dispatch(assignTaskAPI(req, loginData?.access_token || ''))
  }

  const onCancel = (reason: string): void => {
    onUpdateStatus(SaleTaskStatus.CANCEL, reason)
  }

  const onClose = (): void => {
    if (formData.task_type === SaleTaskStatus.TOBRANCH) {
      onUpdateStatus(SaleTaskStatus.TOBRANCH, '')
    } else {
      onUpdateStatus(SaleTaskStatus.TODELIVERY, '')
    }
  }

  const onSave = () => {
    if (!validateForm()) {
      message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
      return
    }
    // setEditMode(false)
    onUpdate()
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const onGetBrandList = (): void => {
    const req: BrandReq = {}
    dispatch(getBrandListAPI(req, loginData?.access_token || ''))
  }

  const onGetBrandBySlugList = (slug: string): void => {
    const req: BrandSlugReq = {
      slug,
    }
    dispatch(getBrandBySlugListAPI(req, loginData?.access_token || ''))
  }

  const getYearData = (): void => {
    dispatch(getYearByModelDataAction({ model: formData.car_model || '' }))
  }

  const resetData = (): void => {
    formData.car_model = ''
    formData.car_year = ''
    dispatch(resetBrandDataAction())
  }

  useEffect(() => {
    if (visible && editMode) {
      onGetBrandList()
    }
  }, [id, visible, editMode])

  useEffect(() => {
    if (formData.car_brand) {
      onGetBrandBySlugList(formData.car_brand || '')
    } else {
      resetData()
    }
  }, [formData.car_brand])

  useEffect(() => {
    if (formData.car_model) {
      getYearData()
    }
  }, [formData.car_model, modelList])

  useEffect(() => {
    if (!visible) return
    if (formData.car_year && formData.car_model) {
      const year = yearList && yearList.find((item: YearData) => item.description === formData.car_year)
      setActiveYear(year)
    } else {
      setActiveYear(undefined)
    }
  }, [formData.car_year, yearList, id, visible])

  const onUpdateCarBrand = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_model: '',
      car_year: '',
      amount_redbook: 0,
    })
  }

  const findEmailByName = (val: string) => {
    const branch = branchList && branchList.find(item => item.name === val)
    const email = []
    if (branch?.email_center) {
      email.push(branch?.email_center)
    }
    if (branch?.email_staff1) {
      email.push(branch?.email_staff1)
    }
    if (branch?.email_staff2) {
      email.push(branch?.email_staff2)
    }
    if (branch?.email_staff3) {
      email.push(branch?.email_staff3)
    }
    return email.join(',')
  }

  const onUpdateBranch = (value: any) => {
    setFormData({
      ...formData,
      branch_customer: value,
      branch_customer_email: findEmailByName(value),
    })
  }

  const onUpdateCarYear = (value: any, key: string) => {
    const year = yearList && yearList.find((item: YearData) => item.year === value)
    setFormData({
      ...formData,
      [key]: value,
      amount_redbook: parseFloat(year?.price || ''),
    })
  }

  const onUpdateCarModel = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_year: '',
      amount_redbook: 0,
    })
  }

  const onUpdateCarPrice = (value: any) => {
    setFormData({
      ...formData,
      amount_redbook: parseFloat(value || 0),
    })
  }

  const onUpdatPostcode = (value: any) => {
    setFormData({
      ...formData,
      delivery_customer_staff_id: '',
      delivery_customer_postcode: value,
    })
  }

  const findStaffEmail = (val: string) => {
    const staff = staffList && staffList.find(item => item.staff_id == val)
    const email = []
    if (staff?.email_center) {
      email.push(staff?.email_center)
    }
    if (staff?.email_staff1) {
      email.push(staff?.email_staff1)
    }
    if (staff?.email_staff2) {
      email.push(staff?.email_staff2)
    }
    if (staff?.email_staff3) {
      email.push(staff?.email_staff3)
    }
    return email.join(',')
  }

  const findStaffName = (val: string) => {
    const staff = staffList && staffList.find(item => item.staff_id == val)
    return staff?.name || ''
  }

  const onUpdatStaff = (value: any) => {
    setFormData({
      ...formData,
      delivery_customer_email: findStaffEmail(value),
      delivery_customer_name: findStaffName(value),
      delivery_customer_staff_id: value,
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    resetReasonForm()
    clearEvent()
    onGetDetail()
    setCancelDialog(false)
    setApproveDialog(false)
    setRejectDialog(false)
    setClosedDialog(false)
    onSuccess()
    setEditMode(false)
  }

  const onAssignEventSuccess = (): void => {
    clearEvent()
    onCloseDrawer()
    onSuccess()
  }

  const onAssignEventError = (): void => {
    clearEvent()
    onCloseDrawer()
    onSuccess()
  }

  const valStyle = {
    color: Theme.colors.black,
    fontFamily: Theme.fontFamily.regular,
    fontSize: Theme.typography.body4,
  }

  const renderPriceChoices = (key: string, name: string) => {
    return (
      <div onClick={() => onUpdateCarPrice(activeYear?.[key] || 0)}>
        <CardPrice className={formData.amount_redbook == (activeYear?.[key] || 0) ? 'actived' : ''}>
          <Statistic
            title={name}
            value={activeYear?.[key] || 0}
            suffix={'บาท'}
            valueStyle={valStyle}
            formatter={(val: any) => numberWithComma(parseFloat(val) || 0)}
          />
        </CardPrice>
      </div>
    )
  }

  const getFooter = (status?: SaleTaskStatus) => {
    const btn = [] as any[]
    // btn.push(<PrimaryButton text="ปิด" btnType="Cancel" onClick={onCloseDrawer} />)
    if (isMe) {
      switch (status) {
        case SaleTaskStatus.INQUE:
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text="On Process"
              btnType="Primary"
              onClick={() => onUpdateStatus(SaleTaskStatus.ONPROCESS)}
            />,
          )

          break
        case SaleTaskStatus.ONPROCESS:
          if (editMode) return
          btn.push(
            <PrimaryButton
              text="ยกเลิกเคส"
              disabled={editMode}
              icon={<CloseOutlined />}
              btnType="Reject"
              onClick={() => setCancelDialog(true)}
            />,
          )
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text={`ยืนยันส่งเคสไปดำเนินการ`}
              btnType="Primary"
              disabled={editMode}
              icon={<MailOutlined />}
              onClick={() => {
                if (!validateForm()) {
                  message.error(`กรุณาระบุข้อมูล${taskTypeName}ให้ครบถ้วนก่อนยืนยันส่งไปยัง${taskTypeName}`)
                  return
                }
                setApproveDialog(true)
              }}
            />,
          )
          break

        case SaleTaskStatus.TODELIVERY:
          btn.push(
            <PrimaryButton
              text="ปฏิเสธเคส"
              icon={<CloseOutlined />}
              btnType="Reject"
              onClick={() => setRejectDialog(true)}
            />,
          )
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text="ปิดเคส"
              btnType="Approve"
              icon={<CheckOutlined />}
              onClick={() => setClosedDialog(true)}
            />,
          )
          break

        default:
          break
      }
    } else {
      switch (status) {
        case SaleTaskStatus.INQUE:
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text="รับงาน"
              btnType="Primary"
              onClick={onAssignTask}
            />,
          )

          break
        default:
          break
      }
    }

    return (
      <Space size="middle" align="baseline">
        {btn}
      </Space>
    )
  }

  const FooterContent = getFooter(detail?.task_sale?.status)

  // const FooterContent = (
  //   <Space size="middle" align="baseline">
  // <PrimaryButton
  //   text="ยกเลิกเคส"
  //   icon={<CloseOutlined />}
  //   btnType="Reject"
  //   onClick={() => setRejectDialog(true)}
  // />
  //     <PrimaryButton
  //       text="ยืนยันส่งไปยังสาขา"
  //       btnType="Approve"
  //       icon={<CheckOutlined />}
  //       onClick={() => setApproveDialog(true)}
  //     />
  //   </Space>
  // )

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const ConfirmApproveDailog = (
    <ConfirmDailog
      title={`ยืนยันส่งเคสไปดำเนินการ`}
      description={`คุณแน่ใจที่จะส่งเคสนี้ไปยัง${taskTypeName}หรือไม่?`}
      confirmBtnTxt="ยืนยัน"
      isShow={approveDialog}
      handleOk={onClose}
      handleCancel={() => setApproveDialog(false)}
      loading={event.IsLoading}
    />
  )

  const getDailogChild = () => {
    return (
      <Row>
        <Col md={24} xs={24}>
          <AppSelect
            name="reasonSelected"
            label="เหตุผล"
            placeHolder="กรุณาเลือกเหตุผล"
            isRequired={true}
            isError={false}
            value={reasonSelected}
            onChange={(val: string) => setReasonSelected(val)}
            menu={getReasonhMenuSelect(reasonList)}
            allowClear={false}
          />
        </Col>
        <Col md={24} xs={24}>
          <AppInput
            name="reasonInput"
            label="หมายเหตุ"
            isRequired={false}
            isError={false}
            value={reasonInput}
            onChange={setReasonInput}
          />
        </Col>
      </Row>
    )
  }

  const resetReasonForm = () => {
    setReasonSelected('')
    setReasonInput('')
  }

  const ConfirmCancelDailog = (
    // <ConfirmQuestion
    //   title="ยกเลิกเคส"
    //   label="เหตุผลที่ยกเลิกเคส"
    //   placeholder="กรุณาระบุเหตุผลที่ยกเลิกเคส"
    //   confirmBtnTxt="ยืนยันยกเลิก"
    //   isShow={cancelDialog}
    //   handleOk={(remark: string) => onCancel(remark)}
    //   handleCancel={() => setCancelDialog(false)}
    //   loading={event.IsLoading}
    // />
    <ConfirmWithChild
      title={'ยกเลิกเคส'}
      isShow={cancelDialog}
      handleOk={() => {
        if (reasonSelected.trim() === '') {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(SaleTaskStatus.CANCEL, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setCancelDialog(false)
      }}
      child={getDailogChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const ConfirmRejectDailog = (
    <ConfirmWithChild
      title={'ยืนยันการปฏิเสธเคสนี้'}
      isShow={rejectDialog}
      handleOk={() => {
        if (reasonSelected.trim() === '') {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(SaleTaskStatus.REJECTED, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setRejectDialog(false)
      }}
      child={getDailogChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const ConfirmClosedDailog = (
    <ConfirmWithChild
      title={'ยืนยันการปิดเคสนี้'}
      isShow={closedDialog}
      handleOk={() => {
        if (reasonSelected.trim() === '') {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(SaleTaskStatus.CLOSED, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setClosedDialog(false)
      }}
      child={getDailogChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const renderStatus = (value?: TaskSaleData) => {
    const result = getSaleTaskStatus(value?.status || '')
    // if (value?.status === SaleTaskStatus.CANCEL) {
    //   return (
    //     <Space direction="vertical">
    //       <StatusTag color={result.color} text={result.text} />
    //       {value?.reason && <Reason>เหตุผล : {value?.reason}</Reason>}
    //       {value?.note && <Reason>หมายเหตุ : {value?.note}</Reason>}
    //     </Space>
    //   )
    // } else {
    //   return (
    //     <Space direction="vertical">
    //       <StatusTag color={result.color} text={result.text} />
    //       {value?.reason && <Note>เหตุผล : {value?.reason}</Note>}
    //       {value?.note && <Note>หมายเหตุ : {value?.note}</Note>}
    //     </Space>
    //   )
    // }
    if (
      value?.task_type === SaleTaskStatus.TOBRANCH &&
      value?.status !== SaleTaskStatus.INQUE &&
      value?.status !== SaleTaskStatus.ONPROCESS
    ) {
      return (
        <Space direction="vertical">
          <StatusTag color={result.color} text={result.text} />
          {/* <Link onClick={() => setShowLogDrawer(true)}>ตรวจสอบสถานะ</Link> */}
          <Link onClick={() => setShowBranchLogDrawer(true)}>ตรวจสอบสถานะจากสาขา</Link>
        </Space>
      )
    }
    return <StatusTag color={result.color} text={result.text} />
  }

  const taskInfo = (
    <BoxWrapper title="">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('หมายเลขเคส', detail?.ams_case_no), null)}
        {renderInfoRow(
          renderInfo(
            'Agent ที่สร้างเคส',
            `${detail?.user?.profile?.first_name}  ${detail?.user?.profile?.last_name}`,
          ),
          renderInfo('สถานะงาน', renderStatus(detail?.task_sale)),
        )}
        {renderInfoRow(
          renderInfo('วันที่สร้างข้อมูล', formatDateTime(detail?.created_at || '')),
          detail?.task_sale?.reason ? renderInfo('เหตุผล', detail?.task_sale?.reason) : null,
        )}
        {renderInfoRow(
          null,
          detail?.task_sale?.note ? renderInfo('หมายเหตุ', detail?.task_sale?.note) : null,
        )}
      </Row>
    </BoxWrapper>
  )

  const carInfo = (
    <BoxWrapper title="ข้อมูลรถยนต์">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ยี่ห้อรถยนต์', detail?.car_loan?.car_brand || ''),
          renderInfo('รุ่นรถ', detail?.car_loan?.car_model),
        )}
        {renderInfoRow(
          renderInfo('ปีที่จดทะเบียน', detail?.car_loan?.car_year),
          renderInfo('ราคาประเมิน', `${numberWithComma(detail?.car_loan?.amount_redbook, true)} บาท`),
        )}
      </Row>
    </BoxWrapper>
  )

  const carForm = (
    <BoxWrapper title="ข้อมูลรถยนต์">
      <Row justify="start" align="top" gutter={[8, 8]}>
        {/* <Col span={24}>
          <Name>ข้อมูลรถยนต์</Name>
        </Col> */}
        <Col md={12} xs={24}>
          <AppSelect
            name="car_brand"
            label="ยี่ห้อรถ"
            isRequired={isRequired('car_brand')}
            isError={isError('car_brand')}
            value={formData.car_brand}
            onChange={onUpdateCarBrand}
            menu={getMenuSelect(brandList, 'name', 'slug')}
            allowClear={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="car_model"
            label="รุ่นรถ"
            isRequired={isRequired('car_model')}
            isError={isError('car_model')}
            value={formData.car_model}
            onChange={onUpdateCarModel}
            menu={getMenuSelect(modelList, 'name', 'name')}
            disabled={formData.car_brand == ''}
            allowClear={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="car_year"
            label="ปีจดทะเบียนรถ"
            isRequired={isRequired('car_year')}
            isError={isError('car_year')}
            value={formData.car_year}
            onChange={onUpdateCarYear}
            menu={getMenuSelect(yearList, 'description', 'description')}
            disabled={formData.car_model == ''}
            allowClear={false}
          />
        </Col>
        {activeYear && (
          <Col md={12} xs={24}>
            <Row>
              <Col md={24} xs={24}>
                <CarName> เลือก Range ราคา Redbook : {activeYear?.description || ''} </CarName>
              </Col>
              <Col md={24} xs={24}>
                {/* {renderPriceChoices('price', 'ราคา')} */}
                {renderPriceChoices('l1', 'ราคา L1')}
                {renderPriceChoices('l2', 'ราคา L2')}
                {/* {renderPriceChoices('l3', 'ราคา L3')} */}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </BoxWrapper>
  )

  const editButton = () => {
    return (
      <PrimaryButton
        text="แก้ไขข้อมูล"
        icon={<EditOutlined />}
        btnType="Primary2"
        onClick={() => {
          onGetBranchListAPI()
          setEditMode(true)
        }}
      />
    )
  }

  const saveButton = () => {
    return (
      <Space>
        <PrimaryButton
          text="ยกเลิก"
          btnType="Cancel"
          onClick={() => {
            initFormData()
            setEditMode(false)
          }}
        />
        <PrimaryButton text="บันทึกข้อมูล" icon={<SaveOutlined />} onClick={onSave} />
      </Space>
    )
  }

  const documentForm = (
    <BoxWrapper title="ข้อมูลรูปภาพและเอกสารรถยนต์">
      <Row gutter={[16, 16]} justify="center">
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.images}
            listType="picture-card"
            name="images"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="เอกสารรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.documents}
            listType="picture-card"
            name="documents"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const documentInfo = (
    <BoxWrapper
      title="ข้อมูลรูปภาพและเอกสารรถยนต์"
      // right={isMe && editable && !editMode && editButton()}
    >
      <Row justify="space-between" gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.car_loan?.images || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="เอกสารรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.task_sale?.documents || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const toBranchForm = () => {
    return (
      <Row gutter={[16, 16]} justify="center">
        <Col md={12} xs={24}>
          <Row justify="space-between" gutter={[16, 1]}>
            <Col md={24} xs={24}>
              <AppSelect
                name="branch_customer"
                label="สาขาที่ลูกค้าต้องการเข้าดำเนินการ"
                isRequired={isRequired('branch_customer')}
                isError={isError('branch_customer')}
                value={formData.branch_customer}
                onChange={onUpdateBranch}
                menu={getBranchMenuSelect(branchList)}
              />
            </Col>
            <Col md={24} xs={24}>
              <AppSelect
                name="branch_customer_reason"
                label="เหตุผล"
                isRequired={isRequired('branch_customer_reason')}
                isError={isError('branch_customer_reason')}
                value={formData.branch_customer_reason}
                onChange={onUpdateData}
                menu={getReasonhMenuSelect(reasonList)}
              />
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={24}>
          <Row justify="space-between" gutter={[16, 1]}>
            <Col span={24}>
              <AppDateItem
                label="วันที่ลูกค้าต้องการเข้า"
                placeHolder="วันที่ลูกค้าต้องการเข้า"
                isRequired={isRequired('branch_customer_date')}
                isError={isError('branch_customer_date')}
                onChange={(date: string) => onUpdateData(date, 'branch_customer_date')}
                date={formData.branch_customer_date}
                allowClear={false}
                picker="date"
                disableDate={(date: any) => {
                  return isAfter(
                    moment(date)
                      .subtract(-1, 'days')
                      .startOf('day')
                      .toString(),
                  )
                }}
              />
            </Col>
            <Col span={24}>
              <AppInput
                name="branch_customer_note"
                label="หมายเหตุ"
                isRequired={isRequired('branch_customer_note')}
                isError={isError('branch_customer_note')}
                value={formData.branch_customer_note}
                onChange={onUpdateData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const toDeliveyForm = () => {
    return (
      <Row gutter={[16, 16]} justify="center">
        <Col md={12} xs={24}>
          <Row justify="space-between" gutter={[16, 1]}>
            <Col md={24} xs={24}>
              <AppSelect
                name="delivery_customer_postcode"
                label="รหัสไปรษณีย์ ของจุดให้บริการ"
                isRequired={isRequired('delivery_customer_postcode')}
                isError={isError('delivery_customer_postcode')}
                value={formData.delivery_customer_postcode}
                onChange={onUpdatPostcode}
                menu={getPostcodeMenuSelect(postcodeList)}
                allowClear={false}
              />
            </Col>
            <Col span={24}>
              <AppDateItem
                label="วันที่นัดหมาย"
                placeHolder="วันที่นัดหมาย"
                isRequired={isRequired('delivery_customer_date')}
                isError={isError('delivery_customer_date')}
                onChange={(date: string) => onUpdateData(date, 'delivery_customer_date')}
                date={formData.delivery_customer_date}
                allowClear={false}
                picker="date"
                disableDate={(date: any) => {
                  return isAfter(
                    moment(date)
                      .subtract(-1, 'days')
                      .startOf('day')
                      .toString(),
                  )
                }}
              />
            </Col>

            <Col span={24}>
              <AppInput
                name="delivery_customer_note"
                label="หมายเหตุ"
                isRequired={isRequired('delivery_customer_note')}
                isError={isError('delivery_customer_note')}
                value={formData.delivery_customer_note}
                onChange={onUpdateData}
              />
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={24}>
          <Row justify="space-between" gutter={[16, 1]}>
            <Col md={24} xs={24}>
              <AppSelect
                name="delivery_customer_staff_id"
                label="รายชื่อตัวแทนที่เข้าดำเนินการ"
                isRequired={isRequired('delivery_customer_staff_id')}
                isError={isError('delivery_customer_staff_id')}
                value={formData.delivery_customer_staff_id}
                onChange={onUpdatStaff}
                menu={getDeliveryStaffMenuSelect(staffList)}
                disabled={!formData.delivery_customer_postcode || staffList.length <= 0}
              />
            </Col>
            <Col md={24} xs={24}>
              <AppSelect
                name="delivery_customer_reason"
                label="เหตุผล"
                isRequired={isRequired('delivery_customer_reason')}
                isError={isError('delivery_customer_reason')}
                value={formData.delivery_customer_reason}
                onChange={onUpdateData}
                menu={getReasonhMenuSelect(reasonList)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const branchForm = (
    <BoxWrapper
      title="ส่งเคสดำเนินการต่อ"
      //  right={isMe && editable && (!editMode ? editButton() : saveButton())}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col md={24} xs={24}>
          <OptionWrapper>
            <Radio.Group value={formData.task_type} onChange={e => onUpdateData(e.target.value, 'task_type')}>
              <Space direction="vertical">
                <Radio value={SaleTaskStatus.TOBRANCH}>ส่งเคสไปยังสาขา</Radio>
                <Radio value={SaleTaskStatus.TODELIVERY}>ส่งเคสไปยัง Delivery</Radio>
              </Space>
            </Radio.Group>
          </OptionWrapper>
        </Col>
        <Col md={24} xs={24}>
          {formData.task_type === SaleTaskStatus.TOBRANCH ? toBranchForm() : toDeliveyForm()}
        </Col>
      </Row>
    </BoxWrapper>
  )

  const toBranchInfo = (
    <BoxWrapper
      title="ข้อมูลสำหรับส่งเข้าสาขา"
      //right={isMe && editable && !editMode && editButton()}
    >
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          {renderInfo('สาขาที่ลูกค้าต้องการเข้าดำเนินการ', detail?.task_sale?.branch_customer || '')}
        </Col>
        <Col span={24}>
          {renderInfo(
            'วันที่ลูกค้าต้องการเข้า',
            formatDateWithoutTime(detail?.task_sale?.branch_customer_date || ''),
          )}
        </Col>
        <Col span={24}>{renderInfo('เหตุผล', detail?.task_sale?.branch_customer_reason || '')}</Col>
        <Col span={24}>{renderInfo('หมายเหตุ', detail?.task_sale?.branch_customer_note || '')}</Col>
      </Row>
    </BoxWrapper>
  )

  const toDeliveryInfo = (
    <BoxWrapper title="ข้อมูลสำหรับส่งเคสไปยัง Delivery">
      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          {renderInfo('รหัสไปรษณีย์ ของจุดให้บริการ', detail?.task_sale?.delivery_customer_postcode || '')}
        </Col>
        <Col span={24}>
          {renderInfo(
            'รายชื่อตัวแทนที่เข้าดำเนินการ',
            `${detail?.task_sale?.delivery_customer_staff_id || ''} - ${detail?.task_sale
              ?.delivery_customer_name || ''}`,
          )}
        </Col>
        <Col span={24}>
          {renderInfo(
            'วันที่นัดหมาย',
            formatDateWithoutTime(detail?.task_sale?.delivery_customer_date || ''),
          )}
        </Col>
        <Col span={24}>{renderInfo('เหตุผล', detail?.task_sale?.delivery_customer_reason || '')}</Col>
        <Col span={24}>{renderInfo('หมายเหตุ', detail?.task_sale?.delivery_customer_note || '')}</Col>
      </Row>
    </BoxWrapper>
  )

  const branchInfo = (
    <>
      {(detail?.task_sale?.status === SaleTaskStatus.ONPROCESS ||
        detail?.task_sale?.status === SaleTaskStatus.TOBRANCH ||
        detail?.task_sale?.status === SaleTaskStatus.TODELIVERY ||
        detail?.task_sale?.status === SaleTaskStatus.REJECTED ||
        detail?.task_sale?.status === SaleTaskStatus.CLOSED) &&
        (detail.task_sale.task_type === SaleTaskStatus.TOBRANCH ? toBranchInfo : toDeliveryInfo)}
    </>
  )

  const getExternalLink = () => {
    return (
      <ExternalLink href={appConfig.externalChaiyoLink} target="_blank" rel="noopener noreferrer">
        &nbsp;
        <SearchOutlined /> ตรวจสอบข้อมูลสาขา, ราคาประเมิน, คำนวนค่างวด
      </ExternalLink>
    )
  }

  const customerInfo = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ชื่อ', detail?.first_name || ''),
          renderInfo('นามสกุล', detail?.last_name || ''),
        )}
        {renderInfoRow(
          renderInfo('เบอร์โทรศัพท์', detail?.mobile || ''),
          renderInfo('ที่อยู่', detail?.car_loan?.address || ''),
        )}
        {renderInfoRow(
          renderInfo(
            'วงเงินที่ลูกค้าต้องการ',
            `${numberWithComma(detail?.car_loan?.amount_limit || 0, true)} บาท`,
          ),
          renderInfo(
            'อัตราผ่อนที่ชำระได้ /เดือน',
            `${numberWithComma(detail?.car_loan?.amount_rate || 0, true)} บาท`,
          ),
        )}
        {renderInfoRow(
          renderInfo('สีรถ', detail?.car_loan?.car_paint || ''),
          renderInfo('ทะเบียน', detail?.car_loan?.car_registration || ''),
        )}
        {renderInfoRow(
          renderInfo('สถาบันการเงินเดิม', detail?.car_loan?.financial_institution || ''),
          renderInfo('ยอดปิดบัญชีไฟแนนซ์เดิม', detail?.car_loan?.finance_account_closing || ''),
        )}
        {renderInfoRow(renderInfo('หมายเหตุ', detail?.car_loan?.note || ''), null)}
        {/* <Col span={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.car_loan?.images || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col> */}
      </Row>
    </BoxWrapper>
  )

  const customerForm = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row justify="space-between" gutter={[8, 8]}>
        <Col md={12} xs={24}>
          <AppInput
            name="first_name"
            label="ชื่อ"
            isRequired={isRequired('first_name')}
            isError={isError('first_name')}
            value={formData.first_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="last_name"
            label="นามสกุล"
            isRequired={isRequired('last_name')}
            isError={isError('last_name')}
            value={formData.last_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="mobile"
            label="เบอร์โทรศัพท์"
            placeHolder="ตัวอย่าง 0888888888"
            isRequired={isRequired('mobile')}
            isError={isError('mobile')}
            value={formData.mobile}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppTextArea
            label="ที่อยู่"
            placeHolder="ที่อยู่"
            isRequired={isRequired('address')}
            isError={isError('address')}
            name="address"
            rows={2}
            value={formData.address}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="amount_limit"
            label="วงเงินที่ลูกค้าต้องการ"
            isRequired={isRequired('amount_limit')}
            isError={isError('amount_limit')}
            value={formData.amount_limit}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="amount_rate"
            label="อัตราผ่อนที่ชำระได้ /เดือน"
            isRequired={isRequired('amount_rate')}
            isError={isError('amount_rate')}
            value={formData.amount_rate}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="car_paint"
            label="สีรถ"
            isRequired={isRequired('car_paint')}
            isError={isError('car_paint')}
            value={formData.car_paint}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="car_registration"
            label="ทะเบียน"
            isRequired={isRequired('car_registration')}
            isError={isError('car_registration')}
            value={formData.car_registration}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="financial_institution"
            label="สถาบันการเงินเดิม"
            isRequired={isRequired('financial_institution')}
            isError={isError('financial_institution')}
            value={formData.financial_institution}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="finance_account_closing"
            label="ยอดปิดบัญชีไฟแนนซ์เดิม"
            isRequired={isRequired('finance_account_closing')}
            isError={isError('finance_account_closing')}
            value={formData.finance_account_closing}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="note"
            label="หมายเหตุ"
            isRequired={isRequired('note')}
            isError={isError('note')}
            value={formData.note}
            onChange={onUpdateData}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <MyTitle>
          หมายเลขเคส : {detail?.ams_case_no} ({detail?.first_name} {detail?.last_name})
        </MyTitle>
      </div>
    </Space>
  )

  const LogsDrawer = <Logs isShow={showLogDrawer} onClose={() => setShowLogDrawer(false)} id={id} />
  const BranchLogsDrawer = (
    <BranchLogs isShow={showBranchLogDrawer} onClose={() => setShowBranchLogDrawer(false)} id={id} />
  )
  const hasWarning = detail?.warning_messages && detail?.warning_messages?.length > 0

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <EventModal
        event={[assignTaskEvent]}
        enable={true}
        successCalback={onAssignEventSuccess}
        errorCalback={onAssignEventError}
      />
      <Row gutter={[16, 16]}>
        {hasWarning && (
          <Col md={24} xs={24}>
            <WarningTask warningMessages={detail?.warning_messages || []} />
          </Col>
        )}
        <Col md={24} xs={24}>
          {taskInfo}
        </Col>
        <Col md={24} xs={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Header>รายละเอียดงาน {getExternalLink()}</Header>
            </Col>
            <Col>{isMe && editable && (!editMode ? editButton() : saveButton())}</Col>
          </Row>
        </Col>
        <Col md={24} xs={24}>
          {editMode ? carForm : carInfo}
        </Col>
        <Col span={24}> {editMode ? customerForm : customerInfo}</Col>
        <Col md={24} xs={24}>
          {editMode ? documentForm : documentInfo}
        </Col>
        <Col md={24} xs={24}>
          {(detail?.task_sale?.status === SaleTaskStatus.ONPROCESS ||
            detail?.task_sale?.status === SaleTaskStatus.TOBRANCH ||
            detail?.task_sale?.status === SaleTaskStatus.TODELIVERY ||
            detail?.task_sale?.status === SaleTaskStatus.REJECTED ||
            detail?.task_sale?.status === SaleTaskStatus.CLOSED) &&
            (editMode ? branchForm : branchInfo)}
        </Col>
      </Row>
      {ConfirmCancelDailog}
      {ConfirmRejectDailog}
      {ConfirmClosedDailog}
      {ConfirmApproveDailog}
      {LogsDrawer}
      {BranchLogsDrawer}
    </DetailDrawer>
  )
}

const MyTitle = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const Header = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h5};
`

const Reason = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.error};
`

const Note = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
`

const CarName = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.medium};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

const ExternalLink = styled.a`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const CardPrice = styled(Card.Grid)`
  width: 50%;
  padding: 12px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  .ant-statistic-title {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
  }

  &.actived {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    span,
    div {
      color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
    }
  }

  @media only screen and (max-width: 720px) {
    width: 50%;
  }
`

const OptionWrapper = styled.div`
  //background-color: #f0eff5;
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.bold};

  .ant-radio-wrapper {
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  }

  .ant-radio-wrapper-checked {
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  }
`

const Link = styled(LinkText)`
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.bold};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectTaskEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    userProfile: selectUserProfile(state),
    assignTaskEvent: selectAssignTaskEvent(state),
    branchList: selectBranchList(state),
    brandList: selectBrandList(state),
    modelList: selectModelList(state),
    yearList: selectYearList(state),
    reasonList: selectReasonList(state),
    postcodeList: selectPostcodeList(state),
    staffList: selectDeliveryStaffList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CustomerTaskDetail)
